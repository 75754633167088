<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <CForm @submit.stop.prevent="submit" v-if="item">
          <CRow>
            <CCol md="12">
              <CInput label="Nama Unit Kerja" placeholder readonly v-model="item.nama_unit_kerja"></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CInput label="Jenis Jabatan" placeholder readonly v-model="nama_jenis_jabatan"></CInput>
            </CCol>
            <CCol md="6">
              <CInput label="Jabatan" placeholder readonly v-model="item.jabatan"></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="3">
              <CInput label="Eselon" placeholder readonly v-model="item.nama_eselon"></CInput>
            </CCol>
            <CCol md="3">
              <CInput label="Pendidikan" placeholder readonly v-model="item.nama_tingkat_pendidikan"></CInput>
            </CCol>
          </CRow>
          <hr />
          <fieldset class="form-group border p-3">
            <legend class="w-auto px-2">Pegawai Existing</legend>
            <CDataTable
              :items="computedItemsBuzz"
              :fields="fieldsBuzz"
              hover
              small
              border
              :loading="loadingBuzz"
              :pagination="true"
              :items-per-page="10"
            >
              <template #pegawai="{item}">
                <td class="py-2">
                  {{ item.nip }} - {{ item.nama_pegawai }}
                </td>
              </template>
            </CDataTable>
          </fieldset>
          <fieldset class="form-group border p-3">
            <legend class="w-auto px-2">Kandidat</legend>
            <CRow>
              <CCol md="12">
                <div role="group" class="form-group">
                  <label class>Nama Pegawai</label>
                  <v-select
                    v-model="filterFormKandidat.nama_pegawai"
                    :options="optionsNamaPegawai"
                    label="nama_pegawai"
                    :reduce="pegawai => pegawai.nama_pegawai"
                    :filterable="true"
                    @search="onSearchNamaPegawai"
                  ></v-select>
                </div>
                <div role="group" class="form-group">
                  <label class>Tingkat Pendidikan</label>
                  <v-select
                    v-model="filterFormKandidat.id_tingkat_pendidikan"
                    :options="optionsTingkatPendidikan"
                    label="nama_tingkat_pendidikan"
                    :reduce="tingkat_pendidikan => tingkat_pendidikan.id_tingkat_pendidikan"
                    :filterable="true"
                    @search="onSearchTingkatPendidikan"
                  ></v-select>
                </div>
              </CCol>
            </CRow>
            <CButtonGroup size="sm">
              <CButton color="info" @click="loadDataKandidat()">
                <CIcon name="cil-search" />Cari
              </CButton>
              <CButton color="warning" @click="resetKandidat()">
                <CIcon name="cil-loop-circular" />Reset
              </CButton>
            </CButtonGroup>
            <hr />
            <CDataTable
              :items="computedItemsKandidat"
              :fields="fieldsKandidat"
              hover
              small
              border
              :loading="loadingKandidat"
              :pagination="true"
              :items-per-page="10"
            >
              <template #pegawai="{item}">
                <td class="py-2">
                  {{ item.nip }} - {{ item.nama_pegawai }}
                </td>
              </template>
              <template #jenis_jabatan>
                <td class="py-2">
                  STRUKTURAL
                </td>
              </template>
              <template #uji_kom="{item}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    class="ml-1"
                    @click="showModalUjiKom(item)"
                  >Lihat</CButton>
                </td>
              </template>
              <template #show_details="{item}">
                <td class="py-2">
                  <CButton
                    color="success"
                    variant="outline"
                    square
                    size="sm"
                    class="ml-1"
                    @click="showModalFormPilih(item)"
                  >Pilih</CButton>
                </td>
              </template>
            </CDataTable>
          </fieldset>
        </CForm>
      </CCardBody>
    </CCard>

    <modal-uji-kom ref="modalUjiKom" />
    <form-pilih ref="formPilih" @done="$emit('done')" />
  </div>
</template>
<script>
  import Swal from "sweetalert2";
  import modalUjiKom from "./modalUjiKom";
  import FormPilih from "./FormPilih";
  import VSelect from "vue-select";
  import _ from "lodash";
  const fieldsBuzz = [
    { key: "number", label: "No" },
    { key: "pegawai", label: "NIP - Pegawai" },
    { key: "pangkat_golongan_ruang", label: "Pangkat/Golongan" },
    { key: "jenis_kelamin", label: "Jenis Kelamin" },
    { key: "umur", label: "Umur" },
    { key: "pendidikan_akhir", label: "Pendidikan Terakhir" },
    { key: "tmt_jabatan", label: "TMT Awal" },
  ];
  const fieldsKandidat = [
    { key: "number", label: "No" },
    { key: "pegawai", label: "NIP - Pegawai" },
    { key: "jenis_jabatan", label: "Jenis Jabatan" },
    { key: "nama_jabatan", label: "Jabatan" },
    { key: "nama_eselon", label: "Eselon" },
    { key: "tmt_jabatan", label: "TMT Awal" },
    { key: "pangkat_golongan_ruang", label: "Pangkat/Golongan" },
    { key: "jenis_kelamin", label: "Jenis Kelamin" },
    { key: "uji_kom", label: "Uji Kom", sorter: false, filter: false },
    {
      key: "show_details",
      label: "Action",
      sorter: false,
      filter: false,
    },
  ];
  export default {
    name: "FormMonitoringJabatanStruktural",
    components: { modalUjiKom, FormPilih, VSelect },
    data() {
      return {
        errorMessage: null,
        form: {},
        loading: false,
        ItemsBuzz: [],
        fieldsBuzz: fieldsBuzz,
        loadingBuzz: false,
        fieldsKandidat: fieldsKandidat,
        ItemsKandidat: [],
        loadingKandidat: false,
        modalUjiKom: false,
        item: null,
        nama_jenis_jabatan: "STRUKTURAL",
        optionsNamaPegawai: [],
        optionsTingkatPendidikan: [],
        filterFormKandidat:{
          nama_pegawai: null,
          id_tingkat_pendidikan: null
        }
      };
    },
    computed: {
      computedItemsBuzz() {
        var number = 1;
        return this.ItemsBuzz.map((item) => {
          return {
            ...item,
            number: number++,
          };
        });
      },
      computedItemsKandidat() {
        var number = 1;
        return this.ItemsKandidat.map((item) => {
          return {
            ...item,
            number: number++,
            kelas_jabatan: item.kelas_jabatan == null ? '-' : ''
          };
        });
      },
    },
    methods: {
      resetKandidat() {
        this.filterFormKandidat.nama_pegawai = null;
        this.filterFormKandidat.id_tingkat_pendidikan = null;
        this.loadDataKandidat();
      },
      async loadDataKandidat() {
        try {
          this.loadingKandidat = true;
          let data = await this.$store.dispatch(
            "monitoring_jabatan_struktural/kandidat", {
            item: this.item,
            data: this.filterFormKandidat
          });
          this.ItemsKandidat = data;
        } catch (ex) {
          this.ItemsKandidat = [];
        } finally {
          this.loadingKandidat = false;
        }
      },
      async loadDataBuzz() {
        try {
          this.loadingBuzz = true;
          let data = await this.$store.dispatch(
            "monitoring_jabatan_struktural/existing",
            this.item
          );
          this.ItemsBuzz = data;
        } catch (ex) {
          this.ItemsBuzz = [];
        } finally {
          this.loadingBuzz = false;
        }
      },
      async submit() {
        const vm = this;
        try {
          this.loading = true;
          if (status >= 200 && status <= 202) {
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil tersimpan!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            }).then(() => {
              if (!vm.item) {
                requestAnimationFrame(() => {
                  this.$refs.observer.reset();
                  this.item = null;
                });
              }
              vm.$emit("done");
            });
          }
        } catch (x) {
          console.log(x);
        } finally {
          this.loading = false;
        }
      },
      showModalUjiKom(item) {
        this.$refs.modalUjiKom.toggle(item);
      },
      showModalFormPilih(item) {
        this.$refs.formPilih.toggle(item, this.item);
      },
      populateInput(item) {
        this.item = item;

        this.loadDataBuzz();
        this.loadDataKandidat();
      },
      async onSearchNamaPegawai(search, loading) {
        loading(true);
        await this.searchNamaPegawai(search, this);
        loading(false);
      },
      searchNamaPegawai: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "monitoring_jabatan_struktural/autocomplete_nama_pegawai",{
          item: vm.item,
          search: search
        });
        if (search) vm.optionsNamaPegawai = options;
      }, 300),
      async onSearchTingkatPendidikan(search, loading) {
        loading(true);
        await this.searchTingkatPendidikan(search, this);
        loading(false);
      },
      searchTingkatPendidikan: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_tingkat_pendidikan",
          search
        );
        if (search) vm.optionsTingkatPendidikan = options;
      }, 300),
    },
  };
</script>